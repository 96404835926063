(function() {
  'use strict';
  angular
    .module('app')
    // .constant('BASE_URL', 'http://localhost:3000')
    // .constant('DOMAIN_URL', 'http://localhost:3001')
    // .constant('PDFGENERATOR', 'http://localhost:4001/pdf_generator')
    // .constant('FORMS_URL', 'http://lvh.me:3003')
    .constant('BASE_URL', 'https://beta.cruzinsurancegroup.com')
    .constant('DOMAIN_URL', 'https://beta.cruzinsurancegroup.com')
    .constant('PDFGENERATOR', 'https://beta.cruzinsurancegroup.com/pdf_generator')
    .constant('FORMS_URL', 'https://betacrmforms.cruzinsurancegroup.com')
    .constant('swal', swal)
    .constant('accounting', accounting)
    .constant('_', _);
})();
