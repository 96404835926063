(function(){
  'use strict';
  angular
    .module('app')
    .component('modalPendingLinkReport', {
      templateUrl: 'app/components/home/modalPendingLinkReport.html',
      controller: ModalPendingLinkAppsController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

  ModalPendingLinkAppsController.$inject = ['user', '$rootScope', '$state', '_', 'globalService','$uibModal','$window'];

  function ModalPendingLinkAppsController($user, $rootScope, $state, _,$globalService, $uibModal, $window) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.params = {};
    vm.params.date_from = moment().subtract(1, 'months').format('YYYY-MM-DD');
    vm.params.date_to = moment().format('YYYY-MM-DD');  
    vm.statuses = [{id: 6, name: 'Pending Link Applications'},{id: 2, name: 'Approved'}]
    vm.statusSelected = vm.statuses[0]; 
    vm.params.status = vm.statusSelected.id;

    vm.$onInit = function(){
      vm.consultLog()
    }

    vm.consultLog = function(){
      vm.params.date_from = moment(vm.params.date_from).format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.params.date_to).format('YYYY-MM-DD');
      $globalService.reportPendingLinkLog(vm.params)
      .then(function(res){
        vm.pendingLinks = res;
        vm.params.token = $user.getCurrentUser().token;
        vm.urlPrint = $globalService.reportExcel('report_pending_link_applications', vm.params);
      })
    }
  }


})();
