(function () {
  'use strict';
  angular
  .module('app')
  .component('processApplication', {
    templateUrl: 'app/components/modals/process_application.html',
    controller: ProcessApplicationController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ProcessApplicationController.$inject = ['$rootScope','globalService'];

  function ProcessApplicationController($rootScope, $globalService) {
    var vm = this;
    vm.save = save;

    vm.$onInit = function() {
      vm.loading = true;
      vm.currentApplication = vm.resolve.currentApplication;
      $globalService.users.query({use_npn: true}).$promise
      .then(function(res){
        vm.agents = res;
        vm.loading = false;
      })
    }


    function save(){
      vm.send = true;
      vm.currentApplication.status = 2;
      if(vm.currentApplication.authorization_link){
        vm.currentApplication.status = 6;
      }
      $globalService.processApplication(vm.currentApplication)
      .then(function(res){
        vm.send = false;
        Swal('Exito',res.message,'success');
        vm.close({$value: res.data})
      }, function(err){
        Swal('Error',err.data.message,'warning');
        vm.cancel({$value: ''});
      })
    }

    vm.cancel = function(){
      vm.dismiss({$value: ''})
    }

    $rootScope.$on('setCurrentApplicant', function(event, data){
      vm.currentApplication = data.current;
      // //console.log(vm.currentApplication.dependents)
    })

  }

})();
