(function() {
  'use strict';
  angular
    .module('app')
    // .run(actionCable)
    .run(run);

  run.$inject = ['$rootScope', '$state', 'authService', '$document', '$transitions', 'user','$window','globalService'];

  function run($rootScope, $state, $authService, $document, $transitions, $user, $window, $globalService) {
    $transitions.onSuccess({ }, function() {
      $document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;
    });

    $transitions.onError({ }, function(trans) {
      switch (trans.error().detail) {
        case 'ALREADY_LOGGED_IN':
          $state.go('home');
          break;
        case 'UNAUTHORIZED':
        //console.log('UNAUTHORIZED')
          unauthorizedHandler();
          break;
        default:
          $state.go('404');
      }
    });

    $rootScope.$on('UNAUTHORIZED', unauthorizedHandler);

    function unauthorizedHandler() {
      if($authService.logout()){
        $state.go('login');
      }
    }

    $globalService.getSystemVersions()
    .then(function(res){
      if($window.localStorage.hasOwnProperty('system_version')){
        var currentVersion = angular.fromJson($window.localStorage.getItem('system_version'))
        if(currentVersion.version != res.version){
          $window.localStorage.setItem('system_version', angular.toJson(res));
          $window.location.reload(true);
        }
        var user = $user.getCurrentUser();
        if(user){
          $globalService.refreshPermissions()
          .then(function(data) {
            $user.setCurrentUser(data.user);
            $rootScope.$emit('updateProfile');
          })
        }
      }else{
        $window.localStorage.setItem('system_version', angular.toJson(res));
        $window.location.reload(true);
      }
    })

    window.addEventListener('offline', function(event){
      $rootScope.$emit('offline', {});
    });

    window.addEventListener('online', function(event){
      $rootScope.$emit('online', {});
    });

    // if($window.localStorage.hasOwnProperty('system_version')){
    //   var current = $window.localStorage.getItem('system_version');
    //   if(current != version || current == undefined){
    //     $window.localStorage.setItem('system_version', version);
    //     $window.location.reload(true);
    //   }
    // }else{
    //   $window.localStorage.setItem('system_version', version);
    //   $window.location.reload(true);
    // }
  }
})();
