(function(){
  'use strict';
  angular
  .module('app')
  .component('signUp', {
    templateUrl: 'app/components/sign-up/sign-up.html',
    controller: SignUpController
  });

  SignUpController.$inject = ['$window', '$state','$stateParams','user', 'authService','toastr'];

  function SignUpController($window, $state, $stateParams, $user, $authService, toastr) {
    var vm = this;
    vm.send = false;

    vm.$onInit = function(){
      var classPage = angular.element(document.querySelector('#classPage'));
      if(classPage.hasClass('login-page')){
        classPage.removeClass('login-page');
        classPage.addClass('register-page');
      }
    }


  }
})();
